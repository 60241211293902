
import { defineComponent, onMounted, ref } from 'vue'
import {version} from './version'


export default defineComponent({
  name: 'App',
  components: {
  },

  setup(){
    
    if(process.env.NODE_ENV == 'development'){
        window.alert = console.warn
    }
    console.log("version: " + version)

    return{ 

    }
  },
  methods:{
  }
})
