import { createRouter, createWebHistory } from 'vue-router'
import Empty from '../views/Empty.vue'

const routes = [
  {
    path:"/",
    component:Empty,
    children:[
      { 
        path:'',
        component: () => import(/* webpackChunkName: "Home" */ '../views/Home.vue'),
      },
    ]
  },
  {
    path:"/mapwho-owner",
    component:Empty,
    children:[
      { 
        path:'',
        component: () => import(/* webpackChunkName: "MapWhoOwner" */ '../views/data_vis/MapWhoOwner.vue'),
      },
    ]
  },
  {
    path:"/mapwho-brand",
    component:() => import(/* webpackChunkName: "MapWhoBrand" */ '../views/data_vis/MapWhoBrand.vue'),
  },
  {
    path:"/mapwhere-city",
    component:Empty,
    children:[
      { 
        path:'',
        component: () => import(/* webpackChunkName: "MapWhereCity" */ '../views/data_vis/MapWhereCity.vue'),
      },
    ]
  },
  {
    path:"/map-next",
    component:Empty,
    children:[
      { 
        path:'',
        component: () => import(/* webpackChunkName: "MapNext" */ '../views/data_vis/MapNext.vue'),
      },
    ]
  },
  {
    path:"/hotel-locator",
    component:Empty,
    children:[
      { 
        path:'',
        component: () => import(/* webpackChunkName: "HotelLocator" */ '../views/data_vis/HotelLocator.vue'),
      },
    ]
  },
  {
    path:"/design-examples-y3/:brand",
    component:Empty,
    children:[
      { 
        path:'',
        component: () => import(/* webpackChunkName: "DesignExamplesY3" */ '../views/data_vis/DesignExamplesY3.vue'),
      },
    ]
  },
  {
    path:"/design-examples-bestnew",
    component:Empty,
    children:[
      { 
        path:'',
        component: () => import(/* webpackChunkName: "DesignExamplesBestNew" */ '../views/data_vis/DesignExamplesBestNew.vue'),
      },
    ]
  },
  {
    path:"/design-companies-y3/:brand",
    component:Empty,
    children:[
      { 
        path:'',
        component: () => import(/* webpackChunkName: "DesignCompaniesY3" */ '../views/data_vis/DesignCompaniesY3.vue'),
      },
    ]
  },
  {
    path:"/design-companies-bestnew",
    component:Empty,
    children:[
      { 
        path:'',
        component: () => import(/* webpackChunkName: "DesignCompaniesBestNew" */ '../views/data_vis/DesignCompaniesBestNew.vue'),
      },
    ]
  },
  {
    path:"/suppliers-y1/:brand",
    component:Empty,
    children:[
      { 
        path:'',
        component: () => import(/* webpackChunkName: "SuppliersY1" */ '../views/data_vis/SuppliersY1.vue'),
      },
    ]
  },
  {
    path:"/suppliers-bestnew",
    component:Empty,
    children:[
      { 
        path:'',
        component: () => import(/* webpackChunkName: "SuppliersBestNew" */ '../views/data_vis/SuppliersBestNew.vue'),
      },
    ]
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

export default router
